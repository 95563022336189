import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';

@Component({
	selector: 'uc-site-logo',
	templateUrl: './site-logo.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteLogoComponent extends BaseNavlinkComponent {
	@Input() isMobileShowing = false;
	@Input() expandedNav = '';
	ucLogo = 'assets/img/logo.svg';
}
