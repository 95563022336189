import {
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { Environment } from '@uc/utilities';
import { Subscription } from 'rxjs';
import { clearingMenuOptions, primaryMenuOptions } from '../static/navigation.properties';
import {
	fetchRecommendedUnis,
	fetchAdviceArticles,
	fetchClearingArticles,
	fetchClearingUnis,
} from '../data-access/+store/navigation.actions';
import {
	AuthenticationService,
	CustomCookieService,
	Role,
} from '@uc/shared/authentication';
import { NavigationToggleService } from '../data-access/navigation-toggle.service';
import { ScreenWidth } from '@uc/web/shared/data-models';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FADE_IN_OUT } from '@uc/shared/ui';
import { getClientDetailsData, getStudentDetailsData } from '@uc/web/shared/data-access';

@Component({
	selector: 'uc-navigation',
	templateUrl: './navigation.component.html',
	animations: [FADE_IN_OUT],
})
export class NavigationComponent implements OnInit, OnDestroy {
	@Output() toggleFixed = new EventEmitter<boolean>();

	navItems: { name: string; key: string }[] = [];
	expandedNav = '';
	clearingEnabled!: boolean;
	showTickertape!: boolean;
	clientAuthenticated = false;
	userAuthenticated!: boolean;
	isPanelVisible = false;
	isMobileNavShowing = false;
	showMobile!: boolean;

	studentDetails = this._store.selectSignal(getStudentDetailsData);
	clientDetails = this._store.selectSignal(getClientDetailsData);

	private _breakpointSub = this._breakpointObserver
		.observe(`(max-width: ${ScreenWidth.LG - 1}px)`)
		.subscribe((result) => {
			this._closeMobileMenu();
			this.closeNavbarPanel();
			this.showMobile = result.matches;
		});

	private _navSub!: Subscription;
	private _routerSub!: Subscription;

	constructor(
		@Inject('environment') protected environment: Environment,
		@Inject(DOCUMENT) private _document: Document,
		private _store: Store,
		private _router: Router,
		private _breakpointObserver: BreakpointObserver,
		private _authSrv: AuthenticationService,
		private _navToggleSrv: NavigationToggleService,
		private _customCookiesSrv: CustomCookieService,
	) {
		this.clearingEnabled = environment.clearingEnabled;
		environment.clearingEnabled
			? (this.navItems = clearingMenuOptions)
			: (this.navItems = primaryMenuOptions);
	}

	ngOnInit(): void {
		this._store.dispatch(fetchRecommendedUnis());
		this._store.dispatch(fetchAdviceArticles());

		if (this.clearingEnabled) {
			this._store.dispatch(fetchClearingUnis());
			this._store.dispatch(fetchClearingArticles());
		}

		const role = this._customCookiesSrv.get('role');
		this.userAuthenticated = role === Role.STUDENT;
		this.clientAuthenticated = role === Role.CLIENT;

		this._routerSub = this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this._isUniversityOrCoursePage(event)) {
					this.showTickertape = false;
				} else {
					this.showTickertape = true;
				}
			}
		});

		this._navSub = this._navToggleSrv.closeNav$.subscribe((val) => {
			if (val) {
				this.closeNavbarPanel();
				this._closeMobileMenu();
			}
		});
	}

	ngOnDestroy(): void {
		this._navSub?.unsubscribe();
		this._breakpointSub?.unsubscribe();
		this._routerSub?.unsubscribe();
	}

	signOut() {
		this._authSrv.signOut();
	}

	showSubmenu(subnav: string): void {
		this.expandedNav = subnav !== this.expandedNav ? subnav : '';
		this.isPanelVisible = this.expandedNav !== '';

		const visibleNavs = this._document.querySelectorAll(
			'.submenu:not(.out-of-sight)',
		);
		visibleNavs.forEach((element) => {
			const id = element.getAttribute('id');
			if (id !== subnav) {
				element.classList.add('out-of-sight');
			}
		});

		const subNav = this._document.getElementById(subnav) as HTMLElement;
		subNav.classList.toggle('out-of-sight');
	}

	showMobileMenu() {
		const mobileNav = this._document.getElementById('mobile-nav');
		const mobileParent = this._document.getElementById('mobile-parent');
		mobileNav?.classList.toggle('invisible');
		mobileParent?.classList.toggle('h-nav');
		this.isMobileNavShowing = !this.isMobileNavShowing;
		this.toggleFixed.emit(this.isMobileNavShowing);
	}

	closeNavbarPanel() {
		this.expandedNav = '';
		const visibleNavs = this._document.querySelectorAll(
			'.submenu:not(.out-of-sight)',
		);

		visibleNavs.forEach((element) => element.classList.add('out-of-sight'));
		this.isPanelVisible = false;
	}

	private _closeMobileMenu() {
		const mobileNav = this._document.getElementById('mobile-nav');
		const mobileParent = this._document.getElementById('mobile-parent');
		mobileNav?.classList.add('invisible');
		mobileParent?.classList.remove('h-nav');
		this.isMobileNavShowing = false;
		this.toggleFixed.emit(this.isMobileNavShowing);
	}

	private _isUniversityOrCoursePage(event: NavigationEnd) {
		const [segment1, segment2] = event.url.split('/').filter(Boolean);
		return segment1 === 'universities' && segment2 && segment2 !== 'clearing';
	}
}
