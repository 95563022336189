export const primaryMenuOptions = [
	{ name: 'Courses', key: 'courses' },
	{ name: 'Universities', key: 'unis' },
	{ name: 'Open Days', key: 'events' },
	{ name: 'Rankings', key: 'rankings' },
	{ name: 'Advice', key: 'advice' },
	{ name: 'Guides', key: 'guides' },
	{ name: 'Statements', key: 'statements' },
];

export const clearingMenuOptions = [
	{ name: 'Clearing', key: 'clearing' },
	{ name: 'Courses', key: 'courses' },
	{ name: 'Universities', key: 'unis' },
	{ name: 'Open Days', key: 'events' },
	{ name: 'Rankings', key: 'rankings' },
	{ name: 'Advice', key: 'advice' },
	{ name: 'Statements', key: 'statements' },
];
