import { ChangeDetectionStrategy, Component } from '@angular/core';
import { rankings } from '../../../static/subnav-rankings.properties';

@Component({
	selector: 'uc-subnav-rankings',
	templateUrl: './subnav-rankings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavRankingsComponent {
	data = rankings;
}
