import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, EnvironmentApiService } from '@uc/utilities';
import { EMPTY, catchError, map } from 'rxjs';
import { FeaturedArticles, Tickertape } from '../models/navigation.models';
import {
	GET_CLEARING_RECOMMENDED_UNIS,
	GET_CLEARING_TICKERTAPE,
	GET_RECOMMENDED_UNIS,
	GET_TICKERTAPE,
} from './queries.graphql';
import { Apollo } from 'apollo-angular';
import {
	RecommendedClearingUniversity,
	RecommendedUniversity,
} from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _clearingEnabled = false;
	constructor(
		@Inject('environment') environment: Environment,
		private _http: HttpClient,
		private _apollo: Apollo,
		private _apiSrv: EnvironmentApiService,
	) {
		this._clearingEnabled = environment.clearingEnabled;
	}

	getFeaturedArticle(categoryId?: number) {
		const params = {
			cat_id: categoryId ?? null,
		};

		return this._http.post<FeaturedArticles>(
			this._apiSrv.PHP_URL + 'Articles/uc_art_get_featured_editor.php',
			params,
		);
	}

	getRecommendedUnis() {
		return this._apollo
			.query<{ recommendedUniversities: Partial<RecommendedUniversity>[] }>({
				query: GET_RECOMMENDED_UNIS,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.recommendedUniversities;
				}),
			);
	}

	getClearingRecommendedUnis() {
		return this._apollo
			.query<{
				clearingRecommendedUniversities: Partial<RecommendedClearingUniversity>[];
			}>({
				query: GET_CLEARING_RECOMMENDED_UNIS,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.clearingRecommendedUniversities;
				}),
			);
	}

	getTickertapeData() {
		return this._clearingEnabled
			? this._getClearingTickertapes()
			: this._getUcTickertapes();
	}

	private _getUcTickertapes() {
		return this._apollo
			.query<{
				tickertape: Tickertape[];
			}>({
				query: GET_TICKERTAPE,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.tickertape;
				}),
			);
	}

	private _getClearingTickertapes() {
		return this._apollo
			.query<{
				clearingTickertape: Tickertape[];
			}>({
				query: GET_CLEARING_TICKERTAPE,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.clearingTickertape;
				}),
			);
	}

	getAllArticles() {
		return this._http.get<any>(this._apiSrv.PHP_URL + 'Articles/uc_arts_get.php');
	}
}
