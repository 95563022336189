import { PromoSlot } from '@uc/web/shared/data-models';
import { NavCard } from '../models/navigation.models';

export const formatDataForInfoCard = (card: PromoSlot): NavCard => {
	const { image, title, description, link } = card;
	return {
		image,
		heading: title,
		description,
		href: link,
		title: 'Find out more',
		external: link.includes('universitycompare.com') ? false : true,
		uniId: card.university?.id,
	};
};
