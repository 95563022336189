<div class="flex mr-6">
	<a
		routerLink="/"
		(click)="isMobileShowing || expandedNav !== '' ? closeNav() : null"
		class="inline-flex items-center pt-1 text-sm font-medium text-gray-900 border-red-500"
	>
		<div class="flex items-center flex-shrink-0">
			<img
				class="-mt-1 rounded-md"
				alt="UniCompare Logo"
				width="40"
				height="40"
				[ngSrc]="ucLogo | cdn"
			/>
		</div>
	</a>
</div>
