import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ModalComponent, SearchComponent } from '@uc/shared/ui';
import { CdnPathPipe, DropdownDirective, EntitiesPipe } from '@uc/utilities';
import { ImpressionDirective, WebCoreModule } from '@uc/web/core';
import { StateNavigationEffects } from './data-access/+store/navigation.effects';
import {
	_STATE_NAVIGATION_FEATURE_KEY,
	reducer,
} from './data-access/+store/navigation.reducer';
import { AvatarUserMenuComponent } from './components/avatar-user-menu/avatar-user-menu.component';
import { BaseNavlinkComponent } from './components/base-navlink.component';
import { NavBrowseAllComponent } from './components/nav-browse-all/nav-browse-all.component';
import { NavCardLandscapeComponent } from './components/nav-card-landscape/nav-card-landscape.component';
import { NavCardPortraitComponent } from './components/nav-card-portrait/nav-card-portrait.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { SecondaryNavDesktopComponent } from './components/secondary-nav-desktop/secondary-nav-desktop.component';
import { SecondaryNavMobileComponent } from './components/secondary-nav-mobile/secondary-nav-mobile.component';
import { SiteLogoComponent } from './components/site-logo/site-logo.component';
import { MobileSubnavContainerComponent } from './components/subnavigation/mobile-subnav-container/mobile-subnav-container.component';
import { SubnavAdviceComponent } from './components/subnavigation/subnav-advice/subnav-advice.component';
import { SubNavCoursesComponent } from './components/subnavigation/subnav-courses/subnav-courses.component';
import { SubnavEventsComponent } from './components/subnavigation/subnav-events/subnav-events.component';
import { SubnavGuidesComponent } from './components/subnavigation/subnav-guides/subnav-guides.component';
import { SubnavRankingsComponent } from './components/subnavigation/subnav-rankings/subnav-rankings.component';
import { SubnavStatementsComponent } from './components/subnavigation/subnav-statements/subnav-statements.component';
import { SubNavUnisComponent } from './components/subnavigation/subnav-unis/subnav-unis.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SubnavClearingComponent } from './components/subnavigation/subnav-clearing/subnav-clearing.component';
import { TickertapeComponent } from './components/tickertape/tickertape.component';
import {
	NewBadgeSvgComponent,
	BurgerSvgComponent,
	UserIconSvgComponent,
	LeftArrowSvgComponent,
	RightArrowSvgComponent,
	ArrowUpSvgComponent,
	NavMenuUpArrowSvgComponent,
	ArrowRightV3SvgComponent,
	ArrowDownSvgComponent,
	CloseModalSvgComponent,
} from '@uc/shared/svg';
import { WriteReviewBtnComponent } from '@web/shared/ui';

const components = [
	NavigationComponent,
	SecondaryNavDesktopComponent,
	SubNavCoursesComponent,
	SubNavUnisComponent,
	SubnavEventsComponent,
	SubnavRankingsComponent,
	SubnavGuidesComponent,
	SubnavAdviceComponent,
	SubnavStatementsComponent,
	SubnavClearingComponent,
	NavLinkComponent,
	NavCardPortraitComponent,
	NavCardLandscapeComponent,
	NavBrowseAllComponent,
	MobileSubnavContainerComponent,
	BaseNavlinkComponent,
	SecondaryNavMobileComponent,
	AvatarUserMenuComponent,
	SiteLogoComponent,
	TickertapeComponent,
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CdnPathPipe,
		DropdownDirective,
		SearchComponent,
		ModalComponent,
		ImpressionDirective,
		CommonModule,
		RouterModule,
		WebCoreModule,
		StoreModule.forFeature(_STATE_NAVIGATION_FEATURE_KEY, reducer),
		EffectsModule.forFeature([StateNavigationEffects]),
		NgOptimizedImage,
		UserIconSvgComponent,
		LeftArrowSvgComponent,
		RightArrowSvgComponent,
		NewBadgeSvgComponent,
		BurgerSvgComponent,
		ArrowUpSvgComponent,
		NavMenuUpArrowSvgComponent,
		ArrowRightV3SvgComponent,
		ArrowDownSvgComponent,
		CloseModalSvgComponent,
		EntitiesPipe,
		WriteReviewBtnComponent,
	],
})
export class WebSharedFeatureNavigationModule {}
