import { ChangeDetectionStrategy, Component } from '@angular/core';
import { statements } from '../../../static/subnav-statements.properties';

@Component({
	selector: 'uc-subnav-statements',
	templateUrl: './subnav-statements.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavStatementsComponent {
	data = statements;
}
