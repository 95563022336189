export const courses = {
	sublinks: [
		{
			heading: 'Popular Course Categories',
			param: 's',
			links: [
				{ title: 'Accounting', value: 'accounting' },
				{ title: 'Economics', value: 'economics' },
				{ title: 'History', value: 'history' },
				{ title: 'Nursing', value: 'nursing' },

				{ title: 'Business', value: 'business' },
				{ title: 'Education', value: 'education' },
				{ title: 'Law', value: 'law' },
				{ title: 'Physiotherapy', value: 'physiotherapy' },

				{ title: 'Computer Science', value: 'computer science' },
				{ title: 'Engineering', value: 'engineering' },
				{ title: 'Marketing', value: 'marketing' },
				{ title: 'Pharmacy', value: 'pharmacy' },

				{ title: 'Criminology', value: 'criminology' },
				{ title: 'Finance', value: 'finance' },
				{ title: 'Medicine', value: 'medicine' },
				{ title: 'Psychology', value: 'psychology' },
			],
		},
	],
	furtherInformation: [
		{
			image: '',
			heading: 'Take our quick degree quiz',
			description:
				'Find the ideal uni course for you with our Course Degree Quiz. Get answers in minutes!',
			href: '/degree-quiz/quick-quiz',
			title: 'Take Quick Quiz',
			external: false,
		},
		{
			image: '',
			heading: 'Take our full degree quiz',
			description:
				'Get more tailored course suggestions with our full Course Degree Quiz and apply with confidence.',
			href: '/degree-quiz/full-quiz',
			title: 'Take Full Quiz',
			external: false,
		},
	],
	browse: '/courses/undergraduate',
};
