import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-mobile-subnav-container',
	templateUrl: './mobile-subnav-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSubnavContainerComponent {
	@Input() section = '';
}
