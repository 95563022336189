import { ChangeDetectionStrategy, Component } from '@angular/core';
import { guides } from '../../../static/subnav-guides.properties';

@Component({
	selector: 'uc-subnav-guides',
	templateUrl: './subnav-guides.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavGuidesComponent {
	data = guides;
}
