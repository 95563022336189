@if (tickertape$ | async; as tickertape) {
	@if (tickertape.length > 0) {
		<div
			class="h-20 px-3 sm:h-16"
			[ngClass]="{
				'bg-uc-clearing-100 text-white': clearingEnabled,
				'bg-uc-blue-50 text-uc-blue-900 border-uc-blue-200': !clearingEnabled,
			}"
		>
			<div
				class="text-center h-20 sm:h-16 flex items-center justify-between w-full sm:w-[548px] lg:w-[800px] mx-auto"
			>
				<button (click)="onClickLeft()">
					<uc-left-arrow-svg
						[customStyles]="
							clearingEnabled ? 'bg-uc-clearing-100' : 'text-uc-blue-900'
						"
					/>
				</button>
				@for (tape of tickertape; track tape; let i = $index) {
					@if (displayedUniIndex === i) {
						<div
							ucImpression
							(onImpression)="onTickertapeImpression(tape.university.id)"
							(click)="onTickertapeClick(tape.university.id)"
							class="sm:w-[452px] lg:w-[700px]"
						>
							@if (!tape.external) {
								<a
									[routerLink]="tape.url"
									rel="noopener sponsored"
									class="text-sm font-semibold leading-5"
									[ngClass]="{ 'text-white': clearingEnabled }"
								>
									{{ tape.title }}
									@if (tape.description) {
										<span class="hidden md:inline ml-1.5 font-normal">
											{{ tape.description }}
										</span>
									}
								</a>
							}
							@if (tape.external) {
								<a
									(click)="trackWebsiteClick(tape)"
									[attr.href]="tape.url"
									target="_blank"
									rel="noopener sponsored"
									class="text-sm font-semibold leading-5"
									[ngClass]="{ 'text-white': clearingEnabled }"
								>
									{{ tape.title }}
									@if (tape.description) {
										<span class="hidden md:inline ml-1.5 font-normal">
											{{ tape.description }}
										</span>
									}
								</a>
							}
						</div>
					}
				}
				<button (click)="onClickRight()">
					<uc-right-arrow-svg
						[customStyles]="
							clearingEnabled ? 'bg-uc-clearing-100' : 'text-uc-blue-900'
						"
					/>
				</button>
			</div>
		</div>
	}
}
