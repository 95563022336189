import { ClearingService } from '@uc/utilities';

const clearingSrv = new ClearingService();

export const clearing = {
	sublinks: [
		{
			heading: 'Clearing Universities & Courses',
			param: '',
			links: [
				{
					title: 'Search Clearing Courses',
					value: 'courses/clearing',
					description: `Discover Clearing ${clearingSrv.getYear()} courses.`,
				},
				{
					title: 'Universities in Clearing',
					value: 'universities/clearing',
					description: 'Find Clearing courses at UK Unis.',
				},
				{
					title: 'Clearing Hub',
					value: 'clearing',
					description: `Your complete guide to Clearing ${clearingSrv.getYear()}.`,
					new: true,
				},
			],
		},
	],
};
