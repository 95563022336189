<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-3 lg:container">
		<!-- left panel -->
		<div class="lg:pt-8 lg:col-span-2 lg:pb-11 lg:grid lg:grid-cols-3">
			@for (section of data.sublinks; track section) {
				<div>
					@if (section.heading) {
						<p class="subnav-heading">
							{{ section.heading }}
						</p>
					}
					<div
						[ngClass]="{ 'lg:mt-11': !section.heading }"
						class="grid grid-cols-1 mb-8 gap-y-8"
					>
						@for (link of section.links; track link) {
							<uc-nav-link
								[paramKey]="section.param"
								[basePath]="data.browse"
								[linkData]="link"
							></uc-nav-link>
						}
					</div>
				</div>
			}
		</div>

		<!-- divider -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		@if (articles$ | async; as articles) {
			<div
				class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:col-span-1 lg:pb-11 bg-gray-50 side-style"
			>
				<p class="subnav-heading">Recommended Articles</p>
				@for (link of articles; track link; let last = $last) {
					<div class="subnav-link-padding" [ngClass]="{ 'lg:mb-8': !last }">
						<uc-nav-link
							[basePath]="data.browse"
							[linkData]="link"
						></uc-nav-link>
					</div>
				}
			</div>
		}
	</div>
</div>

<uc-nav-browse-all [path]="data.browse" page="Advice"></uc-nav-browse-all>
