import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavigationToggleService {
	private _closeNav = new Subject<boolean>();
	closeNav$ = this._closeNav.asObservable();

	initClose(type: boolean) {
		this._closeNav.next(type);
	}
}
