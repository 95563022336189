import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavLinkClickService } from '@uc/web/core';

@Component({
	selector: 'uc-nav-browse-all',
	templateUrl: './nav-browse-all.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBrowseAllComponent extends BaseNavlinkComponent {
	@Input() path = '';
	@Input() page = '';

	constructor(public navLinkClickSrv: NavLinkClickService) {
		super();
	}
}
