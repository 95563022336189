export const advice = {
	sublinks: [
		{
			heading: 'Advice Categories',
			param: '',
			links: [
				{
					title: 'A-Levels',
					value: '/advice/a-levels',
					description: 'All the A-level advice you need.',
				},
				{
					title: 'Apprenticeships',
					value: '/advice/apprenticeships',
					description: 'Advice on apprenticeships.',
				},
				{
					title: 'GCSE',
					value: '/advice/gcses',
					description: 'Learn to master your GCSEs.',
				},
				{
					title: 'General',
					value: '/advice/student',
					description: 'Advice articles on all things uni.',
				},
			],
		},
		{
			heading: '',
			param: '',
			links: [
				{
					title: 'Personal Statement',
					value: '/advice/personal-statement',
					description: 'Advice on your statement.',
				},
				{
					title: 'Postgraduate',
					value: '/advice/postgraduate',
					description: 'Info on postgraduate study.',
				},
				{
					title: 'Student Finance',
					value: '/advice/student-finance',
					description: 'Advice on managing finance.',
				},
				{
					title: 'Student Health',
					value: '/advice/student-health',
					description: 'Physical & mental health advice.',
				},
			],
		},
		{
			heading: '',
			param: '',
			links: [
				{
					title: 'Studying Abroad',
					value: '/advice/studying-abroad',
					description: 'Advice for adventuring abroad.',
				},
				{
					title: 'Teacher Advice',
					value: '/advice/teacher',
					description: 'Advice articles for teachers.',
				},
				{
					title: 'UCAS Advice',
					value: '/advice/ucas',
					description: 'Advice on navigating UCAS.',
				},
				{
					title: '',
					value: '',
					description: '',
				},
			],
		},
	],
	browse: '/advice',
	methods: {
		setClearingNavMenu: (clearingEnabled: boolean) => {
			if (clearingEnabled) {
				return {
					title: 'Guides',
					value: '/guides',
					description: 'Explore our guides.',
				};
			} else {
				return {
					title: 'Work Advice',
					value: '/advice/work-experience',
					description: 'Advice for your next steps.',
				};
			}
		},
	},
};
