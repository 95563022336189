<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-3 lg:container">
		<!-- left panel -->
		<div class="lg:pt-8 lg:col-span-2 lg:pb-11">
			@for (section of data.sublinks; track section) {
				<div>
					<p class="subnav-heading">{{ section.heading }}</p>
					<div class="grid grid-cols-1 lg:grid-cols-4 subnav-link-padding">
						@for (link of section.links; track link) {
							<uc-nav-link
								[paramKey]="section.param"
								[basePath]="data.browse"
								[linkData]="link"
							/>
						}
					</div>
				</div>
			}
		</div>

		<!-- divider -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		<div
			class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:col-span-1 lg:pb-11 bg-gray-50 side-style"
		>
			@for (card of data.furtherInformation; track card) {
				<uc-nav-card-landscape
					(linkClicked)="onQuizButtonClick(card.href)"
					[data]="card"
				/>
			}
		</div>
	</div>
</div>

<uc-nav-browse-all [path]="data.browse" page="Courses"></uc-nav-browse-all>
