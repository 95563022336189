import { createAction, props } from '@ngrx/store';
import { Link, NavCard, Tickertape } from '../../models/navigation.models';

// generic error
export const loadNavError = createAction(
	'[Navigation] load error',
	props<{ error: any }>(),
);

// ug unis
export const fetchRecommendedUnis = createAction('[Navigation] fetch recommended unis');

export const updateRecommendedUnis = createAction(
	'[Navigation] update recommended unis',
	props<{ unis: NavCard[] }>(),
);

// cle unis
export const fetchClearingUnis = createAction(
	'[Navigation] fetch recommended clearing unis',
);
export const updateClearingUnis = createAction(
	'[Navigation] update recommended clearing unis',
	props<{ clearingUnis: NavCard[] }>(),
);

// advice
export const fetchAdviceArticles = createAction('[Navigation] fetch advice articles');

export const updateAdviceArticles = createAction(
	'[Navigation] update advice articles',
	props<{ articles: Link[] }>(),
);

export const fetchClearingArticles = createAction(
	'[Navigation] fetch clearing advice articles',
);
export const updateClearingArticles = createAction(
	'[Navigation] update clearing advice articles',
	props<{ clearingArticles: any }>(),
);

// tickertape
export const fetchTickertape = createAction('[Navigation API] fetch tickertape');

export const updateTickertape = createAction(
	'[Navigation] update tickertape',
	props<{ tickertape: Tickertape[] }>(),
);
