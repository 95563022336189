<div class="relative">
	<div class="lg:pt-8 lg:pb-11 lg:container">
		@for (section of data.sublinks; track section) {
			<div>
				<p class="subnav-heading">{{ section.heading }}</p>
				<div class="grid grid-cols-1 subnav-link-padding lg:grid-cols-3 lg:pb-0">
					@for (link of section.links; track link) {
						<uc-nav-link
							[paramKey]="section.param"
							[basePath]="data.browse"
							[linkData]="link"
						></uc-nav-link>
					}
				</div>
			</div>
		}
	</div>
</div>

<uc-nav-browse-all [path]="data.browse" page="Guides"></uc-nav-browse-all>
