import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_NAVIGATION_FEATURE_KEY, State } from './navigation.reducer';

export const getNavigationState = createFeatureSelector<State>(
	_STATE_NAVIGATION_FEATURE_KEY,
);

export const getStateNavUnis = createSelector(
	getNavigationState,
	(state: State) => state.unis,
);

export const getStateNavArticles = createSelector(
	getNavigationState,
	(state: State) => state.articles,
);

export const getStateNavClearingUnis = createSelector(
	getNavigationState,
	(state: State) => state.clearingUnis,
);

export const getStateNavClearingArticles = createSelector(
	getNavigationState,
	(state: State) => state.clearingArticles,
);

export const getStateNavTickertape = createSelector(
	getNavigationState,
	(state: State) => state.tickertape,
);
