export const rankings = {
	sublinks: [
		{
			heading: 'Ranking Categories',
			param: '',
			links: [
				{
					title: 'Accommodation',
					value: '/rankings/accommodation',
					description: 'University accommodation rankings.',
				},
				{
					title: 'Course Quality',
					value: '/rankings/course-quality',
					description: 'Unis ranked by course quality.',
				},
				{
					title: 'Employability',
					value: '/rankings/career-opportunities',
					description: 'Top-rated unis for employability.',
				},
				{
					title: 'Student Life',
					value: '/rankings/student-life',
					description: 'Unis ranked for student life.',
				},
			],
		},
		{
			heading: 'Regional Rankings',
			param: '',
			links: [
				{
					title: 'England',
					value: '/rankings/england',
					description: 'The top-rated unis in England.',
				},
				{
					title: 'Scotland',
					value: '/rankings/scotland',
					description: 'The top-rated unis in Scotland.',
				},
				{
					title: 'Wales',
					value: '/rankings/wales',
					description: 'Top-rated universities in Wales.',
				},
				{
					title: 'Northern Ireland',
					value: '/rankings/northern-ireland',
					description: 'Unis in Northern Ireland ranked.',
				},
			],
		},
	],
	furtherInformation: [
		{
			image: '/assets/img/Graduate_Career_Rankings_Image_Botton_Web_02.png',
			heading: 'Top 100 Universities',
			description:
				'Taken from 131,500+ data points from students attending university to help future generations',
			href: '/rankings/all',
			title: 'Find out more',
			external: false,
		},
		{
			image: '/assets/img/Rankings_Image_CTA_App_01.png',
			heading: 'About our Rankings',
			description:
				'Discover university rankings devised from data collected from current students.',
			href: '/rankings',
			title: 'Find out more',
			external: false,
		},
	],
	browse: '/rankings',
};
