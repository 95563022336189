import { Component, inject } from '@angular/core';
import { initLogout } from '@uc/shared/authentication';
import { NavigationToggleService } from '../data-access/navigation-toggle.service';
import { LoginModalComponent, ModalService } from '@uc/shared/ui';
import { Store } from '@ngrx/store';
import { resetStudentDetails, resetClientDetails } from '@uc/web/shared/data-access';

@Component({ template: '' })
export class BaseNavlinkComponent {
	private _navToggleSrv = inject(NavigationToggleService);
	private _modalSrv = inject(ModalService);
	private _store = inject(Store);

	closeNav() {
		this._navToggleSrv.initClose(true);
	}

	showModalPanel() {
		this._modalSrv.setContent(LoginModalComponent);
		this._modalSrv.show();
	}

	signOut() {
		this._store.dispatch(resetStudentDetails());
		this._store.dispatch(resetClientDetails());
		this._store.dispatch(initLogout());
	}
}
