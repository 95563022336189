export const guides = {
	sublinks: [
		{
			heading: 'Guide Categories',
			param: '',
			links: [
				{
					title: 'University Subject Guides',
					value: '/guides/subject',
					description: 'Learn about hundreds of university subjects.',
				},
				{
					title: 'University Career Guides',
					value: '/guides/career',
					description: 'Discover hundreds of different career options.',
				},
				{
					title: 'University City Guides',
					value: '/guides/city',
					description: 'Explore everything that UK cities have to offer.',
				},
			],
		},
	],
	browse: '/guides',
};
