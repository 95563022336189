export const unis = {
	sublinks: [
		{
			heading: 'Search by Type',
			param: 'utype',
			links: [
				{
					title: 'City Universities',
					value: 'city',
					description: 'Filter your search by city universities.',
				},
				{
					title: 'Rural Universities',
					value: 'rural',
					description: 'Discover unis in the countryside.',
				},
				{
					title: 'Coastal Universities',
					value: 'coastal',
					description: 'Take your search to the coast.',
				},
				{
					title: 'Russell Group Universities',
					value: 'russell',
					description: 'Search by Russell Group Unis.',
				},
			],
		},
		{
			heading: 'Search by Region',
			param: 'loc',
			links: [
				{
					title: 'London Universities',
					value: 'ldn',
					description: 'Interested in London? Search here.',
				},
				{
					title: 'Scottish Universities',
					value: 'scot',
					description: 'Discover universities in Scotland',
				},
				{
					title: 'Welsh Universities',
					value: 'wales',
					description: 'Want to study in Wales? Search here.',
				},
				{
					title: 'Northern Irish Universities',
					value: 'ni',
					description: 'Discover uni’s in Northern Ireland.',
				},
			],
		},
	],
	browse: 'universities',
};
