import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavLinkClickService } from '@uc/web/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { Link } from '../../models/navigation.models';

@Component({
	selector: 'uc-nav-link',
	templateUrl: './nav-link.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLinkComponent extends BaseNavlinkComponent {
	@Input() basePath = '';
	@Input() paramKey = '';
	@Input() linkData!: Link;

	constructor(public navLinkClickSrv: NavLinkClickService) {
		super();
	}

	getRouterParams(key: string, value: string) {
		return { [key]: value };
	}
}
