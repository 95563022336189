import { ChangeDetectionStrategy, Component } from '@angular/core';
import { courses } from '../../../static/subnav-courses.properties';
import { DegreeQuizGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-subnav-courses',
	templateUrl: './subnav-courses.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavCoursesComponent {
	data = courses;

	constructor(private _degreeQuizGTMSrv: DegreeQuizGTMService) {}

	onQuizButtonClick(absolutePath: string) {
		const quizVersion = absolutePath.includes('full-quiz') ? 'long' : 'short';
		this._degreeQuizGTMSrv.onQuizButtonClick(quizVersion);
	}
}
