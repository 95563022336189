import { gql } from 'graphql-tag';

const GET_RECOMMENDED_UNIS = gql`
	query getRecommendedUnis($product: Product = UNDERGRADUATE, $limit: Int = 3) {
		recommendedUniversities(product: $product, limit: $limit) {
			id
			name
			slug
			order
			profile(product: $product) {
				cover_image
				external_url
			}
			stats {
				recommended_percentage
			}
			address {
				region
			}
		}
	}
`;

const GET_CLEARING_RECOMMENDED_UNIS = gql`
	query getClearingRecommendedUniversities($limit: Int = 3) {
		clearingRecommendedUniversities(limit: $limit) {
			id
			name
			logo
			region
			recommended_percentage
			external_url
			slug
			cover_image
			order
		}
	}
`;

const GET_TICKERTAPE = gql`
	query getTickertape {
		tickertape {
			external
			id
			title
			url
			description
			university {
				id
				name
			}
		}
	}
`;

const GET_CLEARING_TICKERTAPE = gql`
	query getClearingTickertape {
		clearingTickertape {
			external
			id
			is_tracked
			title
			url
			description
			university {
				id
				name
			}
		}
	}
`;

export {
	GET_RECOMMENDED_UNIS,
	GET_CLEARING_RECOMMENDED_UNIS,
	GET_TICKERTAPE,
	GET_CLEARING_TICKERTAPE,
};
