@if (section === 'courses') {
	<uc-subnav-courses></uc-subnav-courses>
}
@if (section === 'clearing') {
	<uc-subnav-clearing></uc-subnav-clearing>
}
@if (section === 'unis') {
	<uc-subnav-unis></uc-subnav-unis>
}
@if (section === 'events') {
	<uc-subnav-events></uc-subnav-events>
}
@if (section === 'rankings') {
	<uc-subnav-rankings></uc-subnav-rankings>
}
@if (section === 'guides') {
	<uc-subnav-guides></uc-subnav-guides>
}
@if (section === 'advice') {
	<uc-subnav-advice></uc-subnav-advice>
}
@if (section === 'statements') {
	<uc-subnav-statements></uc-subnav-statements>
}
